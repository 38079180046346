/* Scoped Styles for ReportDownloader Component */

/* Root container for the ReportDownloader */
#app.report-downloader {
    display: flex;
    height: 100vh;
}

/* Background container for ReportDownloader */
#app.report-downloader .background {
    display: flex;
    width: 100%;
    height: 100vh;
}

#app.report-downloader h1 {
    font-size: 2.5rem;
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: 7500;
    text-align: center; /* Center title */
    font-family: "Open Sans"
}

/* Main content container (image and report links side by side) */
#app.report-downloader #main-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 5rem;
}

/* Image container */
#app.report-downloader #image-container {
    flex: 2; /* Increase the space the image container takes */
    max-width: 60%; /* Increase the max width of the image */
    padding: 20px;
}

#app.report-downloader .screenshot {
    width: 100%;
    height: auto; /* Ensure the image scales properly */
    object-fit: cover; /* Ensure the image covers the space nicely */
}

/* Report Links Styles */
#app.report-downloader #report-links {
    flex: 1; /* Button section will take up less space than the image */
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 50vh;
    align-items: center;
}

/* Anchor links styled as buttons */
#app.report-downloader #report-links a {
    font-size: 1.1rem;
    color: white;
    background-color: #92BB45; /* Modern green button */
    padding: 15px 25px;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    margin-bottom: 10px;
    display: inline-block; /* Ensures anchor behaves like button */
    cursor: pointer;
    position: relative; /* Enables lifting effect */
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
    z-index: 1; /* Ensure it stays above other elements */
    font-family: "Open Sans"
}

#app.report-downloader #report-links a:hover {
    background-color: #6F8E33; /* Darker green on hover */
    color: white;
    transform: translateY(-3px); /* Stronger lift effect */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* More noticeable shadow */
    z-index: 2; /* Raise the element above others during hover */
    font-family: "Open Sans"
}

/* No reports available message */
#app.report-downloader #report-links p {
    font-size: 1rem;
    color: #e74c3c;
    text-align: center;
    margin-top: 20px;
    font-weight: 500;
    font-family: "Open Sans"
}
