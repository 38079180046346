
.background{
    background-size: cover;
    background-position:center;
    display:flex;
    top: 0;
    left: 0;
    filter: contrast(110%);
    overflow:auto;
    background-color: #1A1B1C;
    height: 100vh;

    .container{
        display: flex
    }
      .contentContainer{
       flex:1;
        margin-left: 0vw;
        transition: margin-left 0.3s ease;
      }
      .SDVTitle{
        color: #92BB45;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 800;
        padding: 20px 30px 20px 40px;
        font-family: Open Sans;
         }
         .main{
   
            margin-top:3vh;
            flex: 1;
            /* overflow:hidden; */
            overflow:auto;
            
          }
          .mainWindowGap{
            display: flex;
            gap:1vh;
          }
          .ontologyLoader{  display: flex;gap:1vh;margin-top:2%;margin-left:4.5vw}
        .ontologyIframe{ 
              width: 88vw; height: 76vh; border: none}
          .chapterTitle{
            color: white;
            display: flex;
            align-items: start;
            font-weight: 400;
            font-size: 16px;
            font-family: Open Sans;
            margin-left:2.6em;
          }
          .arrowStyle{
            width:8px;
            height:16px;
            margin-top:4px;
          }
          .subTitle{
            left: 11vw;
            color:#86BC2A;
            font-size: 16px;
            font-family: Open Sans;
            font-weight: 400
            }
            .artifactTitle{
            font-family: Open Sans;
            font-size: 20px;
            font-weight: 600;
            color:white;
            margin-left: 2em;
            padding:12px 0px 0px 8px;
              }
              
           
  }
  .ontologyLoader{  display: flex;gap:1vh;margin-top:2%;margin-left:4.5vw}
  .ontologyIframe{ 
    width: 88vw; height: 76vh; border: none}
  .bottomRightElement{
    position: absolute;
    bottom: 24px;
    right: 24px;
    align-items:end;
    cursor:pointer;
  }
  .chatBotLogo{
     position: fixed;
     bottom: 24px;
     right: 24px;
     z-index: 9999 
    }
  
.navBar{
    height: 60px;
    width: 100vw;
    background: #252525;
    display: flex;
  }
