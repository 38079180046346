.tabs-container {
  /* font-family: Arial, sans-serif; */
  width: auto;
  margin-top: 24;
  height: 2.375em;
}

.tabs {
  display: flex;
}

.tab {
  padding: 0.625em 0.9375em;
  cursor: pointer;
  border: 0px solid #434343;
  border-bottom: none;
  background-color: "white";
  color: white;
  width: auto;
  text-align: "left";
  align-content: "flex-start";
  /* Body - Regular */
  font-family: Open Sans;
  font-size: 0.875em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375em;
}
.tab ::selection{
  color: #92BB45 !important;
  height: 2px;
}
.tab.selected:after {
  content: "";
  display: block;
  width: auto;
  border-bottom: 0.125em solid #92BB45;
  color: #92BB45 !important;
  margin-top: 0.325em;
  height: 0.125em;
}

.tab-content {
  border: 0.0625em solid #ccc;
  padding: 0.625em;
}
