.SDVMainTitle{
    color: #92BB45;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 800;
    padding: 20px 30px 20px 40px;
    font-family: Open Sans;
     }
.navBar{
        height: 60px;
        width: 100vw;
        background: #252525;
        display: flex;
      }