.sdv-operate-dashboard-wrapper {
  color: white;
  background-color: #151515;
  max-height: 100vh;
  overflow: hidden;

  .sidebar-wrapper {
    position: absolute;
  }

  .right-content {
    margin-left: 60px;
  }

  .top-header {
    position: absolute;
    width: calc(100vw - 60px);
    z-index: 999;
    background: #28292b;
    display: flex;
    align-items: center;
    height: 60px;
    top: 0;

    .sdv-experience-text {
      color: #92bb45;
      font-size: 20px;
      text-transform: uppercase;
      font-weight: 800;
      cursor: pointer;
      padding-left: 40px;
      background-color: #28292b;
    }
  }

  .main-content {
    max-height: calc(100vh - 60px);
    overflow: auto;
    padding: 24px 40px;

    .sdv-operate-title {
      font-family: "Open Sans";
      font-size: 22px;
      font-weight: 600;
      line-height: 32px;
      text-align: left;
    }
    .sdv-operate-subtitle {
      font-family: "Open Sans";
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      padding-top: 9px;
      padding-bottom: 24px;
    }

    .software-update-manager-wrapper,
    .ota-summary-wrapper,
    .validation-summary-wrapper {
      background-color: #1b1b1b;
    }

    .sdv-operate-content-wrapper {
      display: grid;
      grid-template-columns: 4fr 1fr;
      gap: 24px;

      .software-update-manager-wrapper {
        grid-column-start: 1;
        grid-column-end: 3;
        border-radius: 8px;
        /* height: 70vh; */
        /* for local rendering */
        height: 58vh;

        .software-update-title {
          font-family: "Open Sans";
          font-size: 16px;
          font-weight: 600;
          line-height: 16px;
          text-align: left;
          padding: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .software-update-manager {
          width: 100%;
          height: calc(100% - 48px);
          background-color: white;
        }
      }
    }

    .ota-summary-wrapper {
      border-radius: 8px;

      hr {
        height: 0.2px;
        border-width: 0;
        color: gray;
        background-color: gray;
      }

      .ota-summary-title-wrapper {
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
        padding: 16px;
        background-color: #28292b;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        gap:10px;

        .ota-summary-title {
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: 600;
          line-height: 14px;
          text-align: left;
        }

        .vin-number-selection {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 12px;

          .vin-number-selection-text {
            font-family: "Open Sans";
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            text-align: left;
          }

          .vin-id-dropdown {
            height: 38px;
            border: 1px solid #ffffff;
            color: #ffffff;

            svg {
              color: #ffffff;
            }
          }
        }
      }

      .ota-summary-details-wrapper {
        padding: 12px;
      }

      .ota-summary-details {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 16px;
        padding: 12px;

        .ota-summary-details-key {
          flex: 4;
          font-family: "Open Sans";
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          text-align: left;
          color: #bdbdbd;
        }

        .ota-summary-details-value {
          flex: 8;
          font-family: Open Sans;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
        }
      }
    }

    .validation-summary-wrapper {
      border-radius: 8px;

      .validation-summary-title {
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        text-align: left;
        padding: 16px;
        background-color: #28292b;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }

    .validation-status-wrapper,
    .vulnerability-score {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      background-color: #222324;
      box-shadow: 0px 4px 20px 0px #00000066;
      flex: 1;
      padding: 16px;
      font-family: "Open Sans";
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      width: 100%;
      border-radius: 8px;
      position: relative;
      text-decoration:none
    }
    .vulnerability-score {
      .bar-overflow {
        position: relative;
        overflow: hidden;
        width: 100px;
        height: 50px;
      }

      .bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 6px solid #141414;
        border-bottom-color: #7cb305;
        border-right-color: #7cb305;
      }
    }

   
  }
  .subNavigationDiv {
    display: flex;
   
    .link {
      text-decoration: none;
    }
  }
  .chatBotWindow{
    position: fixed;
     bottom: 24px; 
     right: 24px;
    z-index: 9999;
  }
  .chatBotLogo{
    position: absolute;
          bottom: 24px;
          right: 24px;
          align-items: end;
          cursor:pointer;
  }
  .Iframe{
    width: 100%;
    height: 100%;
    background: white;
  }
  .IframeBackground{
    background: black;
    width: 75vw;
    height: 75vh;
  }
}
