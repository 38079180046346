/* src/components/RefreshFAB.css */

/* Floating Action Button Styling */
.fab-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000; /* Ensure the FAB is always on top */
  }
  
  .fab {
    background-color: #28a745; /* Green color */
    color: white;
    border: none;
    border-radius: 50%;
    padding: 16px;
    font-size: 24px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .fab:hover {
    background-color: #218838; /* Darker green when hovered */
  }
  