 .Cpopup {
    display: flex;
    flex-direction: column;
    width: 22vw;
    height: 65vh;
    border-radius: 8px 8px 9px 10px;
    border: 1px 0px 0px 0px;
    background-color: #151515;
    border: 1px solid #B8C0CC;
    align-items: flex-start; 
  }

.Cpopup-title{
    width:100%; 
    justify-content: space-between;
    background-color: #2E2F30;
border-radius: 7px 7px 0px 0px;
border: 1px 0px 0px 0px;
padding: 16px;
border: 1px solid #2B2C2D;
background: #2B2C2D;
font-family: Open Sans;
font-size: 12px;
font-weight: 600;
line-height: 16.34px;
text-align: left;
color:white;
display: flex;
align-items: center;

.Cpopup-title-text{
  font-family: Open Sans;
  font-size:12px;
  font-weight:600;
}
}
.chat-window {
  flex-grow: 1; /* Fill remaining space */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px;
  width: 100%;
  .chat-background{
    display:flex;
    flex-direction:row;
    align-items:flex-end;
    ;
  }
  .chatMan{
    width: Hug (28px)px;
    height: Hug (28px)px;
    gap: 10px;
  }
  .chatPrimaryContect{
    display:flex;
    flex-direction:column;
    width:85%;
    padding-left:6px;
  }
  .chatFixedContentOne{
    color:white;
    border-radius:6px;
    width:100%;
    background-color:#252525;
    padding:8px;
    font-family: Open Sans;
  }
  .chatFixedContentTwo{
    color:white;
    border-radius:6px;
    width:100%;
    background-color:#252525;
    padding:8px;
    font-family: Open Sans;
    margin-top: 2%;
  }
}
.popup-Bottom{
  display: flex;
  width: 99.9%; 
  justify-content: space-between;
  margin-top: 12%;
background-color: #2E2F30;
border-radius: 7px 7px 0px 0px;
border: 1px 0px 0px 0px;
padding: 2%;
border: 1px solid #2B2C2D;
background: #2B2C2D;
}

.popup-bottom-elements{
  width:100%; 
  justify-content: space-between;
  background-color: #2E2F30;
border-radius: 7px 7px 0px 0px;
border: 1px 0px 0px 0px;
padding: 2%;
border: 1px solid #2B2C2D;
background: #2e7ece;
display: flex;

}
  

.question_submit{
  width:100%; 
  background-color: 
  #252525;
    display: flex;
    padding: 10px;
    align-items: center 
}
.embeddings_input_main_div {

  left: 1px;
  gap: 0px;
  border-radius: 0px 0px 8px 9px;
    background-color: #252525;
    width: 100%;
    display: flex;
  }
.embeddings_input_div {
 
  background: none;
  border: none;
  color: #A6A6A6;
  outline: none;
  height: 30px; 
  width: 90%;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
}
.chat_box_submit_button{
  background-color: rgb(208, 247, 244, 0.3);
}
.option{
left: 17px;
padding: 5px 8px 5px 8px;
border-radius: 20px 20px 20px 20px;
border: 1px 0px 0px 0px;
color:white;
font-family: Open Sans;
font-size: 12px;
font-weight: 400;
line-height: 18px;
text-align: left;
width: 70%;
border: 1px solid #92bb45;
border-radius: 20px 20px 20px 20px;
cursor: pointer;
margin-bottom: 10px;
width:"100%"
}
.option:hover{
 background: #4A592D;
}

.optionsGap{
  display:flex;
  margin-bottom: 10px;
  gap:5px;
}
.backmenue{
  display: flex;
  justify-content:center;
  .backButton{
    padding:10px;
    background-color:#92bb45;
    color:white;
    cursor:pointer;
  }
}

.option2{

  left: 17px;
  padding: 5px 8px 5px 8px;
  border: 1px 0px 0px 0px;
  color:white;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  width: 55%;
  border: 1px solid #92bb45;
  border-radius: 20px 20px 20px 20px;
  cursor: pointer
  
  }

  .option2:hover{
    background: #4A592D;
   }
  .option3{
    left: 17px;
    padding: 5px 8px 5px 8px;
    color:white;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;

    line-height: 18px;
    text-align: left;
    width: 40%;
    border: 1px solid #92bb45;
    border-radius: 20px 20px 20px 20px;
    cursor: pointer
    }
    .option3:hover{
      background: #4A592D;
     
     }
    .question-answer {
      display: flex;
     
      flex-direction: column;
      margin-bottom: 10px;
  }
  
  .question {
width: Hug (140px)px;
height: Hug (28px)px;
top: 65px;
left: 17px;
padding: 5px 8px 5px 8px;
gap: 10px;
border-radius: 20px 20px 20px 20px;
border: 1px 0px 0px 0px;
border: 1px solid #92BB45;
color:white;
font-size: 12px;
font-weight: 400;
font-family: Open Sans;
margin-bottom:10px
}
  
.answer {
   
      width: 80%;
      font-family: Open Sans;
      font-size: 12px;
      font-weight: 600;      
gap: 0px;
border-radius: 2px 0px 0px 0px;
border: 1px 0px 0px 0px;
border: 1px solid #E9E9E9;
color: white;
box-shadow: 0px 4px 4px 0px #6E71641A;
align-items: end;
justify-content: end;

margin-bottom: 10px;
margin-left:60px;
padding:5px;
width:80%;
}
.chatListGap{
  padding:16px 6px;
}