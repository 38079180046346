.requirementSummaryConatier {
    display: flex;
    flex-direction: column;
    width: 14.75em;
    height: 68vh;
    border-radius: 0.625em;
    background: #222324;
    margin: 1.5em;
    padding: 1em;
}

.requirementSummaryText {
    display: flex;
    height: 3em;
    padding: 1em;
    gap: 0.625em;
    border-radius: 0.5em 0.5em 0em 0em;
    
    background: rgba(255, 255, 255, 0.04);
    color: white;
    margin-top: -0.9375em;
    width: 120%;
    margin-left: -0.9375em;

}

.emptyStateContaier {
    height: 42.5em;
    width: 50vw;
    display: flex;
    align-items: center;
    justify-content: center
}

.generateTaskContaier {
    display: flex;
    flex-direction: column;
    /* width: 91vh; */
    width: 96vh;
    
    height: 40.625em;
    gap: 1.5em;
    /* border-radius: 0.5em ; */
    background: #1B1B1B;
    margin: 1.5em 1.5em 0em 0em;
    padding: 1em;
    /* overflow-y: auto; */
    overflow-y:hidden;
    overflow-x: hidden;
}

.buttonsContainer {
    /* height: 3.75em;
    display: flex;
    justify-content: flex-end;
    gap: 0.875em;
    background-color: #1B1B1B;
    align-items: center;
    border-radius: 0em 0em 0.5em 0.5em;
    margin-right: 1.6em;
    padding-bottom: 0.5em;
    padding-top: 0.5em;
    padding-right: 1em; */
   

    height: 3.75em;
    display: flex;
    justify-content: flex-end;
    gap: 0.875em;
    background-color: #1B1B1B;
    align-items: center;
    border-radius: 0em 0em 0.5em 0.5em;
     width: 96%;
    /* margin-right: 1.6em;
    padding-bottom: 0.5em;
    padding-top: 0.5em;
    padding-right: 1em; */
    /* margin-top: 1em; */

    
}

.greenBorderButton {
    height: 2.5em;
    padding: 0.25em 0.9375em;
    gap: 0.625em;
    border-radius: 0.125em;
    border: 0.0625em solid var(--Accessible-Green, #92BB45);
    box-shadow: 0em 0.125em 0em 0em #00000004;
    font-family: Open Sans;
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1.375em;
    text-align: center;
    color: #92BB45;
    background-color: transparent;
}

.greenButtonStyle {
    height: 2.5em;
    padding: 0.25em 0.9375em;
    gap: 0.5em;
    border-radius: 0.125em;
    background: #92BB45;
    font-family: Open Sans;
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1.375em;
    text-align: center;
    color: #000;
    cursor: pointer;

}

.taskListContainer {
    display: flex;
    flex-direction: column;
    width: 26%;
    /* width: 26%; */
    height: 27em;
    /* top: 4em; */
    /* left: 1em; */
    padding: 0.625em 0.625em 0.875em 0.625em;
    gap: 0.02em;
    border-radius: 0.25em 0.25em 0.5em 0em;
    background-color: #212121;
    overflow-y: auto;
    overflow-x: hidden;
    align-items: center;

}

.taskListStyle {
    /* width: 8.5em; */
    width:170px;
    margin-top: -0.0em;
    padding: 0.625em;
    border-radius: 0.25em;
    border: 0em 0em 0em 0.0625em;
    justify-content: space-between;
    /* border-left: 0.0625em solid #FA8C16; */
    background-color: #282828;
    text-align: center;
    font-family: Open Sans;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.361875em;
    color: var(--Accessible-Green, #92BB45);
    cursor: pointer
}

.outputContent {
    /* width: 452px; */
    height: 25.625em;
    padding: 1em;
    gap: 1.125em;
    border-radius: 0.25em;
    background: #141414;
    border: 0.0625em solid #595959;
    font-family: Open Sans;
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1.375em;
    text-align: left;
    color: white;
    margin: 1em;
    overflow-y: auto;
    overflow-x: auto;
}

.flexDirectionRow {
    display: flex;
}

.flexDirectionColumn {
    display: flex;
    flex-direction: column;
}

.horizontalLine {
    width: 47.2em;
    height: 0.0625em;
    border: 0.0625em solid #595959;
    background-color: #595959;
}

.refineMentCodeTitle {
    font-family: Open Sans;
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1.375em;
    text-align: left;
    color: #8C8C8C;

}

.toastContainer {
    width: 39em;
    height: 2.75em;
    padding: 0em 0.625em;
    gap: 0.625em;
    border-radius: 0.25em;
    background: #212121;
    align-items: center;
}

.toastMsg {
    font-family: Open Sans;
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1.375em;
    text-align: left;
    color: white;
    
}

.inputStyle {
    height: 1.875em;
    border: 0.0625em solid #8C8C8C;
    background: #595959;
    color: white
}

.taskTitle {
    color: white;
    font-weight: 600;
    font-size: 0.875em;
    padding: 0em 1em;
    margin-top:0em
}