.card-container {
    position: relative;
    display: flex;
    justify-content: space-around;
    gap: 130px;
    align-items: center;
    min-height: 180px;
}
.background{
    background-size: cover;
    background-position:center;
    display:flex;
    top: 0;
    left: 0;
    filter: contrast(110%);
    /* overflow:hidden; */
    overflow:auto;
    background-color: #1A1B1C;
    
    
      .SDVTitle{
        color: #92BB45;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 800;
        padding: 20px 30px 20px 40px;
        font-family: Open Sans;
         }
         .main{
          margin-top: 20px;
          overflow: auto;
          max-height: calc(100vh - 80px);
          background-color: transparent;
          box-shadow: none;
          }
          .chapterTitle{
            color: white;
            display: flex;
            align-items: start;
            font-weight: 600;
            font-size: 22px;
            font-family: Open Sans;
            margin-left:2em;
          }
          .chapterInfo {
            color: white;
            display: flex;
            align-items: start;
            font-weight: 400;
            font-size: 16px;
            font-family: Open Sans;
            margin-left:2.8em;
            margin-top: 10px;
          }
          .claimSummarygraph{
            display: flex;
            flex-direction: column;
            width: 92.5%;
            height: 30%;
            margin-top:24px;
            margin-left: 2.5vw;
            padding: 1px 0px 0px 0px;
            /* opacity:0px; */
            border-radius:8px 0px 0px 0px;
            background-color:#1B1B1B;
          }
          .claimSummaryTitle{
            color: white;
                            margin-left: 1vw;
                            font-family:Open Sans;
                            font-size: 16px;
                            font-weight:600;
                            padding-top: 10px;
          }
          .cardBackground{
            display: flex;
                            flex-direction: row;
                            padding: 15px;
                            height: 40%;
                            margin-left: 0.4vw;
                            gap:2.5vw;
          }
          .alertSummary{
            display: flex;
            width:92.5%;
            height: 39%;
            margin-top: 24px;
            margin-left: 2.5vw;
            padding: 16px 0px 0px 0px;
            gap: 16px;
            /* opacity: 0px; */
            flex-direction: column;
            border-radius: 8px 0px 0px 0px;
            background-color:#1B1B1B;
}
.claimListBackground{
    color: white;
    margin-left: 1vw;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
}
.claimListTableGap{
    display:flex;
    flex-direction: row;
    padding:0px 10px 10px 10px;
    height:35%;
    justify-content:space-around;
  }
  .claimListMargin{
    display: flex;
    color:white;
    font-weight: 600;
    border-radius: 8px;
  }
  .claimID{
    flex: 0 0 auto;
    width: 8.5%;
    padding: 1px;
    text-align: center;
    font-family:Open Sans;
            font-weight: 400;
            font-size: 16px;
  }
  .claimModel{
    flex: 0 0 auto;
    width: 9%;
    padding: 1px;
    text-align: center;
    font-family:Open Sans;
            font-weight: 400;
            font-size: 16px;
  }
  .alertPriority{
    flex: 0 0 auto;
    width: 10%;
    padding: 1px;
    text-align: center;
    font-family:Open Sans;
            font-weight: 400;
            font-size: 16px;
  }
  .modelYear
    {
        flex: 0 0 auto;
        width: 10%;
        padding: 1px;
        text-align: center;
        font-family:Open Sans;
                font-weight: 400;
                font-size: 16px;
  }
  .component{
    flex: 0 0 auto;
    width: 10%;
    padding: 1px;
    text-align: center;
    font-family:Open Sans;
            font-weight: 400;
            font-size: 16px;
  }
  .issue{
    flex: 0 0 auto;
    width: 31%;
    padding: 1px;
    text-align: center;
    font-family:Open Sans;
            font-weight: 400;
            font-size: 16px;
   
  }
  
  .vin{
    flex: 0 0 auto;
    width: 15%;
    padding: 1px;
    text-align: center;
    font-family:Open Sans;
            font-weight: 400;
            font-size: 16px;
  }
  .riskScore{
    flex: 0 0 auto;
    width: 6%;
    padding: 1px;
    font-family:Open Sans;
            font-weight: 400;
            font-size: 16px;
    text-align: left;
  }
  .itemID{
    flex: 0 0 auto;
    width: 7%;
    padding: 3px;
    font-family:Open Sans;
            font-weight: 400;
            font-size: 14px;
    text-align: center;
  }
  .itemModel{
    flex: 0 0 auto;
    width: 10%;
    padding: 3px;
    font-family:Open Sans;
            font-weight: 400;
            font-size: 14px;
    text-align: center;
  }
  .itemPriority{
    flex: 0 0 auto;
    width: 10%;
    padding: 3px;
    font-family:Open Sans;
            font-weight: 400;
            font-size: 14px;
    text-align: center;
  }
  .itemYear{
    flex: 0 0 auto;
    width: 10%;
    padding: 3px;
    font-family:Open Sans;
            font-weight: 400;
            font-size: 14px;
    text-align: center;
  }
  .itemAlert{
    flex: 0 0 auto;
    width: 10%;
    padding: 3px;
    font-family:Open Sans;
            font-weight: 400;
            font-size: 14px;
    text-align: center;
  }
  .itemComment{
    flex: 0 0 auto;
    width: 35%;
    padding: 3px 7px 3px 7px;
    font-family:Open Sans;
            font-weight: 400;
            font-size: 14px;
    text-align: start;
  }
  .itemVin{
    flex: 0 0 auto;
    width: 10%;
    padding: 3px;
    font-family:Open Sans;
            font-weight: 400;
            font-size: 14px;
    text-align: center;
  }
  .itemRiskScore{
    flex: 0 0 auto;
    width: 6%;
    padding: 3px;
    font-family:Open Sans;
            font-weight: 400;
            font-size: 14px;
    text-align: end; 
  }
  .bottomRightElement{
    position: absolute;
    bottom: 24px;
    right: 24px;
    align-items:end;
    cursor:pointer;
  }
  .chatWindow{
    position: fixed;
    bottom: 24px;
    right: 24px;
    z-index: 9999 
   }
}
.container{
    display: flex
}
  .contentContainer{
   flex:1;
    margin-left: 0vw;
    transition: margin-left 0.3s ease;
  }
::-webkit-scrollbar {
    display: none;
      /* Hide scrollbar for IE, Edge, and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    }
.card-container a:nth-child(odd) {
    align-self: flex-end;
}

.card-container a:nth-child(even) {
    align-self: flex-start;
}
tr:hover {
    border: 1px solid green; /* Change border color to green on hover */
}

.contentGap{
  display: flex;
  gap: 24px;
  margin-top:24px;
  margin-left: 2.5vw}

  .jiraBackground{
    background-color: #1B1B1B;
     width: 55.1%;
  }
  .jiraWidget{
    background-color: #222324;
    display: flex;
    justify-content: space-between;
    padding: 11px;
    border-radius: 8px 8px 0px 0px;
  }
  .jiraTitle{color: white;
    font-size:16px;font-family:Open Sans;
    font-weight:600}
  .jiraViewButton{
      align-items: end;
      text-decoration: none;
      color: #92BB45;
      font-size:14px;
      font-family:Open Sans;
      font-weight:600;
  }
  .jiraIssueTitle{
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    width: 8%;
    padding-left:2px;
  }
  .jiraissueDesc{
    padding: 10px;
    font-family:Open Sans;
    font-size: 14px;
    font-weight: 400;
    width: 64%;
  }
  .jiraIssueVersion{
    background-color: #434343;
    padding: 2px 8px;
    border-radius: 2px;
    font-family:Open Sans;
    font-size: 14px;
    font-weight: 400;
  }
  .jiraEpic{
    background-color: #1D39C4;
    padding: 2px 2px 2px 8px;
    border-radius: 2px;
    font-family:Open Sans;
    font-size: 14px;
  }
  .ontology{
     background-color: #1B1B1B;
     width: 38.5% }
  .ontologyBackground{
    background-color: #222324;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 8px 8px 0px 0px;
  }
 .ontologyTitle{ 
  color: white;font-size:16px;
  font-family:Open Sans;
  font-weight:600}
  .ontologyViewButton{
    align-items: end;
    text-decoration: none;
    color: #92BB45;
    font-size:14px;
    font-family:Open Sans;
    font-weight:600
  }
  .ontologyPreiewIframe{
    background-color: white;
    height: 200px;
    width: 100%;
  }

.jiraListItem {
    border-bottom:1px solid  #302E2E;
    border-bottom-color: #302E2E;
        display:flex;
    flex-direction:row;
    align-items:center;
    
}
.jiraListItem:hover {
    border-bottom:1px solid  #92BB45;
    border-bottom-color: #92BB45;
    border-color: #92BB45;
    border:1px solid #92BB45
}  
.navBar{
    height: 60px;
    width: 100vw;
    background: #252525;
    display: flex;
  }