/* StatusOverview.css */

/* Container for the entire StatusOverview component */
.status-component {
  background-color: #f7f7f7;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  min-width: 300px;
  word-wrap: break-word;
  overflow: hidden; /* Prevent overflow */
  color: #333;
  position: relative; /* To position the online/offline text at the top-right */
}

/* Title style (Hardware name) */
.status-component h2 {
  font-size: 18px;
  margin-bottom: 15px;
  text-align: center;
  color: #333;
}

/* Style for the status list */
.status-list {
  list-style-type: none; /* Remove default list bullets */
  padding-left: 0;
  margin: 0;
}

/* List item style for services */
.status-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
}

/* Add a small gap between the service name and its status */
.status-list li span {
  margin-left: 10px;
}

/* Style for the "active" and "inactive" status */
.online {
  color: green;
}

.offline {
  color: red;
}

/* Restart button style */
.restart-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.restart-button:hover {
  background-color: #0056b3;
}

.restart-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

/* Timestamp styling */
small {
  display: block;
  margin-top: 10px;
  color: #888;
  font-size: 12px;
  text-align: center;
}

/* Add styles for the online/offline indicator at the top-right */
.status-component .status-indicator {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 12px; /* Smaller font size for online/offline status */
  font-weight: bold;
  padding: 5px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1); /* Light background for better visibility */
}

/* Optional: Change color of the indicator based on status */
.status-component .status-indicator.online {
  background-color: green;
  color: white;
}

.status-component .status-indicator.offline {
  background-color: red;
  color: white;
}

/* Styling for the voice assist toggle */
.voice-assist-toggle {
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: #333;
}

.voice-assist-toggle label {
  margin-right: 8px;
  font-size: 14px;
  font-weight: bold;
}

/* Switch container */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px; /* Increase height of the switch for a better visual effect */
}

/* Hide the checkbox input */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

/* The round slider circle */
.slider:before {
  position: absolute;
  content: "";
  height: 22px; /* Adjusted circle size */
  width: 26px;
  border-radius: 50%;
  background-color: #92BB45;
  transition: 0.4s;
  left: 4px;  /* Start position on the left */
  bottom: 4px;
}

/* When the checkbox is checked, change the slider background and move the circle */
input:checked + .slider {
  background-color: #4CAF50; /* Green color when checked */
}

input:checked + .slider:before {
  transform: translateX(30px); /* Move the circle to the right */
}

/* Optional: Change slider background color when clicked */
.slider:active:before {
  width: 22px;
  transition: width 0.1s ease;
}
