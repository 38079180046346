/* Scoped Styles for CodebeamerReportDownloader Component */

/* Root container for the CodebeamerReportDownloader */
#app.codebeamer-report-downloader {
    display: flex;
    height: 100vh;
    overflow-y: auto; /* Enable vertical scrolling */
}

/* Background container for CodebeamerReportDownloader */
#app.codebeamer-report-downloader .background {
    display: flex;
    width: 100%;
    height: 100vh;
}

#app.codebeamer-report-downloader h1 {
    font-size: 2.5rem;
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: 7500;
    text-align: center; /* Center title */
    font-family: "Open Sans"
}

/* Main content container (images and buttons side by side) */
#app.codebeamer-report-downloader #main-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; /* Align items to the left */
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    gap: 10px; /* Reduced gap between image and button containers */
}

/* Image container */
#app.codebeamer-report-downloader #image-container {
    display: flex;
    flex-direction: column; /* Stack images vertically */
    justify-content: center; /* Vertically center the images */
    align-items: center;
    flex: 1; /* The image container takes up 1 part of the flex layout */
    max-width: 35%; /* Further reduce the width of the image container */
    padding: 10px; /* Reduced padding for a tighter layout */
    margin-right: 5px; /* Reduced margin between the image container and the button container */
    margin-left: 15rem;
}

#app.codebeamer-report-downloader .screenshot {
    width: 100%;
    height: auto; /* Ensure the image scales properly */
    object-fit: cover; /* Ensure the image covers the space nicely */
    margin-bottom: 10px; /* Reduced space between images */
}

/* Buttons container */
#app.codebeamer-report-downloader #buttons-container {
    flex: 1; /* The button section takes up 1 part of the flex layout */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically center the buttons */
    align-items: center;
    max-width: 20%; /* Further reduce the width of the buttons container */
    gap: 15rem; /* Increased gap between the buttons */
}

/* Button Styles */
#app.codebeamer-report-downloader .button {
    font-size: 1.1rem;
    color: white;
    background-color: #92BB45; /* Modern blue button */
    padding: 15px 25px;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
    font-family: "Open Sans"
}

/* Button hover effect */
#app.codebeamer-report-downloader .button:hover {
    background-color: #6F8E33; /* Darker blue on hover */
    color: white;
    transform: translateY(-3px); /* Lift effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow on hover */
    font-family: "Open Sans"
}
