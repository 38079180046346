.summaryCardMain {
  width:23%;
  height:18rem;
  background-color:#252525;
  border-radius:4px;
  .title{
    background-color:#2F2F30;
    color:white ;
    height:47px;
    display:flex;
    font-family:Open Sans;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    padding-left:16px;
    padding-top:16px;
    border-radius:4px, 4px, 4px, 4px;
  }
  .logo{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:4px, 4px, 4px, 4px
  }
  .graph-summary{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top:16px;
  }
  }
  