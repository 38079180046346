.claim-summary-details-wrapper {
  display: flex;
  color: white;
  overflow: hidden;
  max-height: 100vh;
  background-color: #151515;
  font-size: 14px;

  .sidebar-wrapper {
    position: fixed;
  }

  .right-content {
    margin-left: 60px;
    width: calc(100vw - 80px);

    .top-header {
      position: fixed;
      width: calc(100vw - 60px);
      z-index: 99;
      background: #28292b;
      display: flex;
      align-items: center;

      .sdv-experience-text {
        color: #92bb45;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 600;
        line-height: 33px;
        cursor: pointer;
        padding-left: 40px;
        letter-spacing: 0.05em;
        background-color: #28292b;
      }
    }

    .main-content {
      padding-left: 40px;
      padding-right: 24px;
      /* margin-top: 80px; */
      max-height: calc(100vh - 150px);

      .sdv-quality-bread-crumb {
        padding: 20px 0;
      }

      .claim-analysis-navbar-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        font-family: "Open Sans";
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        text-align: left;

        .claim-analysis-navbar {
          display: flex;
          gap: 50px;
          overflow: hidden;
          justify-content: right;
        }

        .claim-analysis-navbar-icons {
          cursor: pointer;
          width: 36px;
        }

        .navbar-icon-click-options {
          padding: 8px;
          border-radius: 8px;
        }

        .navbar-icon-click-options:hover {
          background-color: #32382c;
          color: #92bb45;
        }
      }
    }

    .button-wrapper {
      display: flex;
      gap: 24px;
      align-items: center;
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: 20px 56px;
      background-color: #151515;

      .escalate-component-problem-button,
      .back-button {
        padding: 8px 15px;
        border-radius: 2px 0px 0px 0px;
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        cursor: pointer;
        border: 1px solid #92bb45;
      }

      .escalate-component-problem-button {
        background-color: #92bb45;
      }
    }
  }

  .claim-summary-wrapper {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 32px;

    .claim-summary-left {
      max-height: calc(100vh - 272px);
      overflow: hidden;

      .claim-list-wrapper {
        display: flex;
        margin-bottom: 22px;
      }

      .classified-claims {
        flex: 0.53;
        background-color: #1b1b1b;

        .classified-claims-title {
          background-color: #252525;
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: 600;
          line-height: 21.79px;
          text-align: left;
          padding: 10px;
        }
      }

      .classified-claims-table {
        background: #1b1b1b;
        padding: 16px;
        max-height: calc(100vh - 320px);
        overflow: auto;
      }

      .claim-list {
        flex: 0.47;
        background-color: #25282399;

        .claim-list-table {
          padding: 16px;
          max-height: calc(100vh - 320px);
          overflow: auto;
        }

        .claim-list-title {
          display: flex;
          background-color: #252525;
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: 600;
          line-height: 21.79px;
          text-align: left;
          padding: 10px;
          justify-content: space-between;
          align-items: center;
          gap: 10px;

          .selected-component {
            font-family: "Open Sans";
            font-size: 14px;
            font-weight: 600;
            line-height: 14px;
            text-align: left;
            color: #ffc53d;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(100% - 90px);
            height: 18px;
          }
        }
      }

      .claim-list-table-header,
      .claim-list-table-row,
      .classified-claims-table-header,
      .classified-claims-table-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .claim-list-table-row,
      .classified-claims-table-row {
        padding: 12px;
        margin-top: 16px;
        background: #282828;
        border-radius: 8px;
        cursor: pointer;
      }

      .claim-list-table-first-column,
      .classified-claims-table-first-column {
        flex: 0.4;
      }

      .claim-list-table-second-column,
      .classified-claims-table-second-column {
        flex: 0.6;
        text-align: end;
      }

      .selected-row {
        border: 1px solid #92bb45;
      }
    }

    .claim-summary-right {
      background-color: #1b1b1b;
      padding: 24px;
      max-height: calc(100vh - 280px);
      overflow: auto;

      .vehicle-health-wrapper {
        padding-bottom: 24px;

        .vehicle-health-title {
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          margin-bottom: 20px;
        }

        .vehicle-health-and-diagnostics {
          background-color: #222324;
          padding: 16px;
          border-radius: 8px;
        }

        .vinId {
          background-color: #222324;
        }

        .vehicle-health-data {
          display: flex;
          width: 100%;
          padding: 20px 0;

          .vehicle-health-data-key {
            flex: 0.26;
            font-family: "Open Sans";
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            text-align: left;
            color: #bdbdbd;
          }

          .vehicle-health-data-value {
            flex: 0.74;
            font-family: "Open Sans";
            font-size: 14px;
            font-weight: 600;
            line-height: 14px;
            text-align: left;
          }
        }

        .vehicle-health-data-component-details-wrapper {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 14px;

          .vehicle-health-data-component-details {
            display: flex;
            gap: 12px;

            .vehicle-health-data-component-key {
              font-family: "Open Sans";
              font-size: 14px;
              font-weight: 400;
              line-height: 14px;
              text-align: left;
              color: #bdbdbd;
            }
          }
        }
      }

      .view-details-wrapper,
      .events-timeline-graph-wrapper,
      .vehicle-knowledge-graph-wrapper {
        padding-bottom: 24px;

        .view-details-heading,
        .events-timeline-graph-heading,
        .vehicle-knowledge-graph-heading {
          padding: 16px;
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: 600;
          line-height: 14px;
          text-align: left;
          background: linear-gradient(0deg, #222324, #222324),
            linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.04),
              rgba(255, 255, 255, 0.04)
            );
        }

        .view-details-heading {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .events-timeline-graph-heading {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .events-timeline-graph-dropdown {
            height: 38px;
            border: 1px solid #ffffff;
            color: #ffffff;

            svg {
              color: #ffffff;
            }
          }
        }

        .vehicle-knowledge-graph,
        .events-timeline-graph {
          width: 100%;
        }

        .vehicle-knowledge-graph {
          background-color: white;
          height: 525px;
        }
      }
    }
  }
}
.bottomRightElement {
  position: absolute;
  bottom: 24px;
  right: 24px;
  align-items: end;
}
.snackbar {
  position: absolute;
  top: 5rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: white;
  background-color: #de8500;
  padding: 1rem;
  width: 500px;
  text-align: center;
  font-size: 1.25rem;
  opacity: 0.9;
}
