.overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  overscroll-behavior: contain;
  overflow: scroll;
}

.popup {
  margin: 4.375em auto;
  padding-bottom: 1.25em;
  background: black;
  border-radius: 0.42em;
  height: 76%;
  position: relative;
  transition: all 5s ease-in-out;
  z-index: 1;
}

.scroll_content {
  overflow: auto;
}

.popup h2 {
  margin-top: 0;
  color: white;
  font-family: Open Sans;
  font-size: 1em;
  font-weight: 600;
  line-height: 1em;
  text-align: left;

}

.popup .close {
  color: white;
  position: absolute;
  top: 0.31em;
  right: 1em;
  transition: all 200ms;
  font-size: 1.75em;
  font-weight: normal;
  text-decoration: none;
  width: 30px;
  height: 30px;
  padding: 0px;
}

.popupTitle {
  font-weight: 600;
  color: white;
  font-size: 1em;
  line-height: 1em;
  text-align: left;
}

.titleContainer {
  width: 100%; 
  padding: 16px;
  gap: 8px;
  border-radius: 6.72px 6.72px 0px 0px;
  background: linear-gradient(0deg, #222324, #222324),
    linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04));

}

@media screen and (max-width: 43.75em) {
  .popup {
    width: 69%;
  }
}