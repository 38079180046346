.mainFrameContainer {

    width: 92.5%;
        height: 450px;
    border-radius: 10px 0px 0px 0px;
    border-radius: 0.625em 0em 0em 0em;
    /* bottom 0 to avoid vertical scroll on sdv hub dashboard */
    margin: 1.5em 2.5em 0em 2.5em;
    background: #1B1B1B;
}

.mainFrameHeadingContainer {
    gap: 0em;
    font-size: 16px;
    font-weight: 600;
    font-family: Open Sans;
    border-radius: 0.5em 0.5em 0em 0em;
    justify-content: space-between;
    background: linear-gradient(0deg, #222324, #222324),
        linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04));
}

.mainFrameHeading {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 1em;
    text-align: left;
    color: var(--White, #FFFFFF);
    padding: 1em;
}

.uploadDocumentContaier {
    width: 50%;
    height: 15.75em;
    top: 4em;
    left: 1em;
    gap: 1em;
    padding: 1em 1.5em 3.125em 1em;
}

.uploadDocumentTitle {
    color: var(--White, #FFFFFF);
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.375em;
    text-align: left;
    height: 2.375em;
}

.clickToUploadContainer {
    display: flex;
    flex-direction: column;
    height: 13.375em;
    padding: 1em 0em;
    gap: 1.25em;
    border-radius: 0.25em;
    background: #141414;
    border: 0.0625em solid #595959;
    align-items: center;
    justify-content: center;
}

.clickTouploadTextContainer {
    display: flex;
    flex-direction: column;
    width: 24.6875em;
    gap: 0.25em;
    margin-bottom: 2.3125em;
    align-items: center;
}

.clickTouploadText {
    font-family: Open Sans;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5em;
    text-align: center;
    color: #FFFFFF;
    width: 24.6875em;
}

.dottenLineBetween {
    width: 24.75em;
    height: 0em;
    top: 3.9375em;
    left: 38.25em;
    gap: 0em;
    border: 0.09375em 0em 0em 0em;
}

.sensorConfig {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-top: 1em;
    padding-left: 1.5em;
    gap: 0.375em;
}

.uploadedFileText {
    font-family: Open Sans;
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1.375em;
    text-align: left;
    color: var(--Accessible-Green, #92BB45);
    margin-top: 10%;
    cursor: pointer;
}
.uploadedStaticFileText {
    font-family: Open Sans;
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1.375em;
    text-align: left;
    color:white;
    cursor: pointer;
}

.codeButtonStyle {
    button {
        margin: 0em;
    }
}

.codeButtonStyle {
    width: 13.5em;
    height: 2.5em;
    padding: 0.25em 0.9375em;
    gap: 0.5em;
    border-radius: 0.125em;
    box-shadow: 0em 0.125em 0em 0em #0000000B;
    align-self: flex-end;
    margin-left: -13.5em;
    font-family: Open Sans;
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1.375em;
    text-align: center;
    color: white;
    background-color: #8C8C8C;
    margin-bottom: 1.100em;
}

.codeButtonStyle :disabled {
    background: #8C8C8C;

}

.codeButtonStyle :enabled {
    background: #92BB45;
}

.guZdik {
    border: none !important;
    height: auto;

}

.guZdik>svg {
    width: 0em !important;
    height: 0em !important;

}

.jWkLDY>span {
    color: transparent !important;
}

.clickTouploadTextContainer input {
    display: flex;
    height: 11.25em;
    width: 28.125em;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    margin-top: -6em;
}

.marginTop {
    margin-top: -0.5em;
}