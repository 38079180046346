.inputFiledContainer {
    display: flex;
    flex-direction: column;
    /* height: 86px; */
    padding: 0px 0px 24px 0px;
}

.marginTopAlign {
    margin-top: -5px
}

.inputHeadingText {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    /* line-height: 22px; */
    text-align: left;
    color: #FFFFFF;
    margin-top: -0.3125em;
    word-break: break-all;
}

.inputContainer {
    height: 32px;
    border-radius: 2px;
    border: 1px solid #8C8C8C;
    background: #595959;
}

.enabledInputContainer {
    background: #141414;
    border: 1px solid #595959;
    width: Fill (629px)px;
    padding: 5px 12px;
    gap: 4px;
    border-radius: 4px;
    color: white !important;
    height: 32px;
}
.enabledInputContainer input[type="color"]::-webkit-color-swatch-wrapper { 
    color: white !important; /* Adjust color as needed */
  }
.ant-input-data-count {
    color: #8C8C8C !important;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: right;
}

.ant-input-suffix {
    color: transparent !important;
    width: 0px !important;
    height: 0px !important;
}

.ant-input {
    color: white !important;
}