.sidebar {
  display: flex;
  position: relative; /* Add position relative to handle the hover state */
  transition: width 0.3s ease;
  background-color: #28292b;
  height:100vh
}

.icons {
  display: inline-flex;
  flex-direction: column;
  padding: 3px 0.3vw;
  align-items: flex-start;
  gap: 0px;
  flex-shrink: 0;
  align-items: center;
  background-color: #28292b;
  border-right: 1px solid #28292b;
  width: 60px;
  .d-icon{
    width:48px;
    z-index: 2;
    padding-left:15px;
    padding-bottom: 0px;
    padding-top: 8px;
  }
  .active-background{
    display: flex;
     align-items: center;
     background-color: black;
     width: 60px;
     height:53px;
     position: relative;
     justify-content: space-evenly
  }
     .bar{
      position: absolute;
      left: 0;
       z-index: 1;
     }
     .icon{
      padding: 10px;
       z-index: 2 
     }
     .icondeck{
      padding: 10px;
      z-index: 2 ;
      margin-top:38vh;
     }

  .active-deck{
    display: flex;
     align-items: center;
     background-color: black;
     width: 60px;
     height:53px;
     position: relative;
     justify-content: space-evenly;
    margin-top:38vh;
     position: relative
  }
}

.iconsActiveBlack {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  flex-shrink: 0;
  border-right: 1px solid #b3b3b3;
  background: black;
}


.expanded {
  min-width: 15.625em;
  padding-top: 0.75em;
  height:100vh
}

.drawer {
  width: 12.5em;
  background-color: #28292b;
}

.open {
  width: 12.5em;
  background-color: #28292b;
}

.ActiveCollapsibleDropdown {
  .sidebarItemBackground{
    display: flex;
     align-items: center;
     background-color: black;
      width: auto;
      height:40px;
      position: relative
  }
  .expanded-area{
    position: relative;
     z-index: 1000
  }
  .click-area{
    display:flex;
    direction:row;
  }
  .mainHeadingExpanded {
    width: 18.625em;
    display: flex;
    justify-content: space-between;
    margin: 0;
    border-radius: 3px;
    background-color: black;
    align-items: center;
    padding-left: 0.3125px;
  }

  .mainHeading {
    width: 18.625em;
    display: flex;
    justify-content: space-between;
    margin: 0;
    border-radius: 3px;
    background: black;
    align-items: center;
    padding-left: 0.3125px;
  }

  .headLine {
    display: flex;
    flex-grow: 1;
    color: #fff;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.05px;
    text-transform: capitalize;
  }

  .logoExpanded {
    margin: 0.2em;
  }

  .primaryLogo {
    margin-right: 0.5em;
  }

  .primaryLogo:hover {
    transform: scale(1.2);
  }

  .subNavigation {
    background-color: black;
    justify-content: start;
    .mainMargin {
      margin-left: 10%;
    }

    .subHeading {
      color: #8a8a8a;
      font-family: Open Sans;
      font-size: 13px;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }
    .ActivesubHeading {
      color: #92bb45;
      font-family: Open Sans;
      font-size: 13px;
      font-weight: 600;
      line-height: normal;
      background: black;
      text-transform: capitalize;
    }

    .subNavigationDiv {
      display: flex;
      gap: 0.375em;
      padding: 5px;
      align-items: center;
      background: black;
      .link {
        text-decoration: none;
      }
    }
  }
}

.activeHeadline {
  display: flex;
  flex-grow: 1;
  color: #92bb45;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.05px;
  text-transform: capitalize;
}


.CollapsibleDropdown {
  .sidebarItemBackground{
    display: flex;
     align-items: center;
     background-color: black;
      width: auto;
      height:40px;
      position: relative
  }

  .click-area{
    display:flex;
    direction:row;
    align-items: center;

  }
  .expanded-area{
    position: relative;
     z-index: 1000
  }
  .d-icon{
    width:80%;
    padding:4px;
  }
  .mainHeadingExpanded {
    width: 18.625em;
    display: flex;
    justify-content: space-between;
    border-radius: 3px;
    background-color: #28292b;
    align-items: center;
    padding-left: 0.625em;
  }

  .mainHeading {
    width: 18.625em;
    display: flex;
    justify-content: space-between;
    border-radius: 3px;
    background-color: #28292b;
    align-items: center;
    padding-left: 0.625em;
  }

  .headLine {
    display: flex;
    flex-grow: 1;
    color: #fff;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.05px;
    text-transform: capitalize;
  }

  .logoExpanded {
    margin: 0.2em;
  }

  .primaryLogo {
    margin-right: 0.5em;
  }

  .primaryLogo:hover {
    transform: scale(1.2);
  }

  .subNavigation {
    margin-left: 10%;

    .subHeading {
      color: #8a8a8a;
      font-family: Open Sans;
      font-size: 13px;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }

    .subNavigationDiv {
      display: flex;
      gap: 0.175em;
      padding: 5px;
      align-items: center;
      justify-content: flex-start;

      .link {
        text-decoration: none;
      }
    }
  }
}

.ActiveCollapsibleDropdownDeck {
  .sidebarItemBackground{
    display: flex;
     align-items: center;
     background-color: black;
      width: auto;
      height:40px;
      position: relative
  }
  background-color: black;
  .mainHeadingExpanded {
    width: 18.625em;
    display: flex;
    justify-content: space-between;
    margin: 0;
    border-radius: 3px;
    background-color: black;
    align-items: center;
    padding-left: 0.3125px;
  }

  .mainHeading {
    width: 18.625em;
    display: flex;
    justify-content: space-between;
    margin: 0;
    border-radius: 3px;
    background: black;
    align-items: center;
    padding-left: 0.3125px;
  }

  .headLine {
    display: flex;
    flex-grow: 1;
    color: #fff;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.05px;
    text-transform: capitalize;
  }

  .logoExpanded {
    margin: 0.2em;
  }

  .primaryLogo {
    margin-right: 0.5em;
  }

  .primaryLogo:hover {
    transform: scale(1.2);
  }

  .subNavigation {
    background-color: black;

    justify-content: start; /* Center the subNavigation content horizontally */
    
    .mainMargin {
      margin-left: 10%;
    }
    .subHeading {
      color: #8a8a8a;
      font-family: Open Sans;
      font-size: 13px;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }
    .ActivesubHeading {
      color: #92bb45;
      font-family: Open Sans;
      font-size: 13px;
      font-weight: 600;
      line-height: normal;
      background: black;
      text-transform: capitalize;
    }

    .subNavigationDiv {
      display: flex;
      gap: 0.375em;
    
      background: black;
      .link {
        text-decoration: none;
      }
    }
  }
}

.CollapsibleDropdownDeck {
  .sidebarItemBackground{
    display: flex;
     align-items: center;
     background-color: black;
      width: auto;
      height:40px;
      position: relative
  }
  background-color: #28292b;
  .mainHeadingExpanded {
    width: 18.625em;
    display: flex;
    justify-content: space-between;
    border-radius: 3px;
    background-color: #28292b;
    align-items: center;
    padding-left: 0.625em;
  }

  .mainHeading {
    width: 18.625em;
    display: flex;
    justify-content: space-between;
    border-radius: 3px;
    background-color: #28292b;
    align-items: center;
    padding-left: 0.625em;
  }

  .headLine {
    display: flex;
    flex-grow: 1;
    color: #fff;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.05px;
    text-transform: capitalize;
  }

  .logoExpanded {
    margin: 0.2em;
  }

  .primaryLogo {
    margin-right: 0.5em;
  }

  .primaryLogo:hover {
    transform: scale(1.2);
  }

  .subNavigation {
    margin-left: 10%;

    .subHeading {
      color: #8a8a8a;
      font-family: Open Sans;
      font-size: 13px;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }

    .subNavigationDiv {
      display: flex;
      gap: 0.175em;
      justify-content: flex-start;

      .link {
        text-decoration: none;
      }
    }
  }
}

/* Add hover styles for the sidebar */
.sidebar:hover .drawer {
  width: 15.625em;
}

.sidebar.open {
  width: 15.625em;
  /* Adjust width as needed */
}

.sidebar:hover:not(.open) {
  width: 15.625em;
}
