.snackbar {
  position: absolute;
  top: 5rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: white;
  background-color: #de8500;
  padding: 1rem;
  width: 500px;
  text-align: center;
  font-size: 1.25rem;
  opacity: 0.9;
}
#alertDetails {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    background-color: #2E2F30;
    width: 100%;
    height: 99.5%;
    border-radius: 10px;
  }
  #alertDetails td, #alertDetails th {
    border: none;
    padding: 8px;
  }
  #alertDetails th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background: #444749;
    color: white;
    font-weight: normal;
  }
  #alertDetails td {
    color: white;
    font-size: 14px;
    text-align: left;
  }
  #narattiveIssue {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  #narattiveIssue td, #narattiveIssue th {
    border: 1px solid #60656A;
    padding: 8px;
  }
  #narattiveIssue th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background: #444749;
    color: white;
    font-weight: normal;
  }
  #narattiveIssue td {
    color: white;
    font-size: 14px;
    text-align: center;
  }
  .blur-background {
    filter: blur(4px); /* Adjust the blur intensity as needed */
    transition: filter 0.3s ease; /* Smooth transition */
  }
  .footer-alert .child:not(:last-child) {
    margin-right: 10px;
  }
  .actionsBtn {
    cursor: pointer;
    width: 25%;
  }
  .actionsBtn1 {
    cursor: pointer;
    width: 99%;
    height: 75%;
  }
  .actionsBtnVScan{
    cursor: pointer;
    width: 35%;
    height: 10%; 
  }
  .claimItem {
    display: flex;
    border-radius: 8px;
    background-color: #2F2F2F;
    color: white;
    border-radius: 8px;
    overflow-y: auto;
    margin-top: 10px;
    font-family:Open Sans;
    font-weight: 400;
    font-size: 14px;
    opacity: 58;
    align-items: center;
    padding: 5px 16px;
    cursor:pointer
}
.claimItem:hover{
  background-color: #92BB45;
  background: #C0FA9D0F;
}