:root {
  --card-width: 7.5rem;
  --card-text-width: 13rem;
}

.App {
  background-color: #323232;
  background-image: url("images/SdvLoginBackground.svg");
  height: 100vh;
  mix-blend-mode: darken;
  overflow: hidden;
}

.Bar {
  margin-left: 27vw;
  height: 99vh;
  margin-bottom: 50px;
}
.SignPopUp {
  width: 500px;
  height: 566px;
  padding: 106px 32px;
  gap: 10vh;
  border-radius: 20px;

  background: linear-gradient(
      151.11deg,
      rgba(0, 0, 0, 0.16) 0%,
      rgba(0, 0, 0, 0.0424363) 82.84%,
      rgba(0, 0, 0, 0) 125.46%
    ),
    linear-gradient(
      210.82deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.136) 100%
    );
}

.sdv_exp {
  width: 15vw;
  font-family: Open Sans;
  font-size: 2vw;
  font-weight: 800;
  line-height: 35px;
  text-align: left;
  color: #92bb45;
}

.sdv_exp_subtext {
  margin-top: 2vh;
  width: 20vw;
  font-family: Open Sans;
  font-size: 1vw;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: #acacac;
}
.signin-button {
  padding: 10px 20px;
  border: none;
  background-color: #92bb45; /* Change to your desired background color */
  color: white;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 34px;
}

.icon {
  margin-left: 5px;
}
.signin-button {
  margin-left: 0.3vw;
  width: Hug (144px) px;
  height: Hug (48px) px;
  padding: 12px 24px 12px 24px;
  gap: 8px;
  border-radius: 4px 0px 0px 0px;
  /* opacity: 0px; */
  background-color: #92bb45; /* Change to your desired background color */
  color: white;
  font-size: 16px;
  border-radius: 2px;
  cursor: pointer;
}

.icon {
  margin-left: 5px;
}

.AuthPage {
  position: absolute;
  top: 10vh;
  right: 10vh;
  width: 40%;
  height: 85%;
  padding: 106px 32px;
  gap: 10vh;
  border-radius: 20px;
  background: linear-gradient(
      151.11deg,
      rgba(0, 0, 0, 0.16) 0%,
      rgba(0, 0, 0, 0.0424363) 82.84%,
      rgba(0, 0, 0, 0) 125.46%
    ),
    linear-gradient(
      210.82deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.136) 100%
    );
}

.AuthPage_title {
  position: absolute;
  top: 2vh;
  left: 2vw;
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #ffffff;
}

.AuthLine {
  position: absolute;
  top: 8vh;
}
.PrivacyLine {
  position: absolute;
  top: 60vh;
  width: 100%;
}

.privacy_heading {
  width: 134.26px;
  height: 15px;
  top: 705px;
  left: 24.41px;
  gap: 0px;
  color: white;
}

.privacy_box {
  width: 600px;
  height: 155px;
  top: 689px;
  margin-left: 0px;
  border-radius: 0px 0px 20px 20px;
  border: 1px 0px 0px 0px;
  border: 1px solid #f0f0f0;
}
.Auth_txt_one {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: white;
}

.Auth_input {
  width: 30vw;
  height: 40px;
  top: 189px;
  left: 33px;
  gap: 0px;
  border-radius: 2px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  color: #8c8c8c;
  border: 1px solid #cccccc;
  background: transparent;
}
.input_lable {
  margin-top: 2vh;
  color: white;
  margin-bottom: 8px;
}
.auth-button {
  border: none;
  width: 15vw;
  background: #92bb45;
  color: white;
  font-size: 16px;
  border-radius: 2px;
  cursor: pointer;
}
.auth_privacy {
  position: absolute;
  width: 100%;
  height: 30%;
  left: 0%;
  bottom: 0;
}

.LoginForm {
  margin-left: -15vw;
  margin-bottom: 5vh;
  height: 90vh;
}
.Car {
  margin-left: -8vw;
  margin-top: 15vw;
}
.bg {
  position: fixed;
  width: 100%;
  height: 100%;
}
.ClickArea {
  position: absolute;
  background-color: transparent;
  height: 200px;
  width: 200px;
  bottom: 80px;
  cursor: pointer;
}
.SignInClickArea {
  background-color: yellow;

  cursor: pointer;
}
.App-child {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
h1 {
  font-size: 8vw;
  color: skyblue;
  margin: 0;
}

.App-heading {
  width: 70%;
  margin: 0 auto;
}
.App-image-car {
  max-width: 80vw;
  height: auto;
  width: auto\9; /* ie8 */
}
.App-image-deloitte {
  width: 12.5vw;
  position: relative;
  left: -1vw;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #1b1b1d;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
}
.footer-img {
  width: 45px;
}
