
.issuePopup {
  position: fixed;
  display: flex;
  top: 0;
  left: 0%;
   width: 100%;
  height: 100%;
  background: rgba(0, 0, 0,0.6); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Adjust the z-index to control stacking order */
}

/* Styles for the popup content */
.popup-title {
  background-color: #252525;
    display: flex;
    padding: 0px 0px 0px 30px;
    border-radius: 1px;
    color: white;
    width: 100%;
    height: 45px;
    align-items: center;
    justify-content: left;
    font-size: 16px;
    font-weight: 600;
    font-family: Open Sans;
    border-radius: 5px;
    border-top: 1px solid gray;
  }

.popup-content {
  background-color: #2E2F30;
  padding: 0px;
  border-radius: 5px;
  color: white;
  width: 40%;
  text-align: center;
  border: 1px solid gray;
  
}

/* Styles for the "Yes" and "No" buttons */

button {
  padding: 10px 20px;
  border: none;
  text-decoration: solid;
  border-radius: 5px;
  cursor: pointer;
}

/* Center the buttons within the popup */
button:first-child {
  margin-right: 10px;
}
.bottomButtons {
  display: flex;
  padding: 0px 0px 30px 10px;
  border-radius: 1px;
  color: white;
  width: 100%;
  height: 45px;
  align-items: center;
  justify-content: left;
}
.yesButton 
{
  margin: 20px;
  background-color: #2E2F30;
  color:white;
  border-radius: 8px;
  border: 1px solid #92BB45
}
.yesButton:hover {
  background-color: #92BB45;
}
.closeButton{
  margin: 20px;
  background-color: #2E2F30;
  color:white;
  border: 1px solid #92BB45;
  border-radius: 8px;
}
.closeButton:hover {
  background-color: #92BB45;
}
.summaryTextScroll{
  text-align:left;
  font-family:Open Sans;
  font-size:13px;
  font-weight:400;
         max-width: 100%;
        max-height: 200px;
        white-space: pre-wrap;
}
.summaryTextScroll::-webkit-scrollbar {
  width: 5px; /* Adjust the width as needed */
}
.summaryTextScroll::-webkit-scrollbar-track {
  background: none; /* Remove background */
}
.summaryTextScroll::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Color of the scrollbar */
  border-radius: 10px; /* Rounded corners */
  border: none; /* Remove border */
}
.summaryTextScroll::-webkit-scrollbar-thumb:hover {
  background-color: #aaa; /* Hover color */
}
.issue-description {  
  display: grid;
  color: white;
  padding: 20px;
  grid-template-columns: 1fr 7fr;

  .lable{
    margin: 10px 20px 0px 0px;
    text-align:left;
    margin-left:10px;
    font-family:Open Sans;
    font-size:14px;
    font-weight:400;
  }
}
.circular-progress{
   width: 100%;
   align-items: center;
   height:200px;
}
.date-label{
  margin: 10px 20px 0px 0px;
  text-align:left;
  margin-left:10px;
  font-family:Open Sans;
  font-size:14px;
  font-weight:400;
}
.llm-summary{
  text-align: left;
  margin-left:2vw;
  font-family:Open Sans;
  font-size:13px;
  font-weight:400;
  padding-bottom:20px;
  color:#92BB45;
}
.issue-description pre {
  overflow-y: scroll;
  text-align:left;
  font-family:Open Sans;
  font-size:13px;
  font-weight:400;
         max-width: 100%;
        max-height: 210px;
        white-space: pre-wrap;
        scrollbar-width: thin;
        padding: 5px;
     
}
.issue-description pre::-webkit-scrollbar {
  width: 1px; /* Adjust the width as needed */
}
.issue-description pre::-webkit-scrollbar-track {
  background: none; /* Remove background */
}
.issue-description pre::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Color of the scrollbar */
  border-radius: 10px; /* Rounded corners */
  border: none; /* Remove border */
}
.issue-description pre::-webkit-scrollbar-thumb:hover {
  background-color: #aaa; /* Hover color */
}

