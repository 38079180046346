.loading-animation {
    /* display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 15px; 
    padding-top: 20px;
    color: #068c3e;

    height: 100vh; */

    display: flex;
    align-items: center;
    justify-content: flex-start; /* Aligns the content at the start (top of the container) */
    flex-direction: column;
    gap: 15px; /* Spacing between SVG and dots */
    color: #068c3e; /* Example: Tomato color for the dots */
    
  }
  
  .loading-animation svg {
    width: 150px; /* Adjust SVG size as needed */
    height: auto;
    fill: #FF6347; /* Change color of the SVG elements */
  }
  
  .loading-dots {
    font-family: monospace;
    font-size: 3rem;
    color: yellowgreen; /* Custom color for the dots (Tomato in this case) */
  }
  
  .dot {
    animation: blink 1.5s infinite step-start;
  }
  
  .dot:nth-child(1) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.4s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  
  
  
  
  