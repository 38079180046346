.mct-report-wrapper {
  color: white;
  background-color: #151515;
  max-height: 80vh;
  overflow: auto;

  .mct-report-title {
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    padding: 16px;
    position: absolute;
    top: 0;
    width: 100%;
    background: linear-gradient(0deg, #222324, #222324),
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.04),
        rgba(255, 255, 255, 0.04)
      );
  }

  .mct-report-table-wrapper {
    padding: 24px;
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    min-width: 80vh;
    flex-direction: column;
    gap: 20px;
  }

  .mct-report-custom-table {
    border-collapse: collapse;
    width: 100%;
    padding: 24px;
  }

  .mct-report-custom-table th,
  .mct-report-custom-table td {
    border: 1px solid rgba(128, 128, 128, 4);
    padding: 16px;
    text-align: center;
  }

  .mct-report-custom-table th {
    background-color: #444749;
  }

  .mct-report-table-body {
    max-height: 70vh;
    overflow: auto;
  }
}
