/* App.css - Avoid resetting button styles globally */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Main Container to apply background styling */
.app-container {
  display: flex;
  flex-direction: column; /* Ensure the content is stacked vertically */
  width: 95%; /* Full width */
  height: 90vh; /* Full height */
  background-color: #1A1B1C; /* Set background color */
  filter: contrast(110%); /* Apply contrast filter */
  overflow: hidden; /* Prevent overflow */
}

/* Main Content Styles */
.main-content {
  flex: 1; /* Allow the content to expand to take available space */
  padding: 20px;
  overflow-y: auto; /* Ensure vertical scrolling is enabled */
  margin-left: 0; /* No left margin */
  margin-right: 0; /* Ensure it spans full width */
  color: #fff;
  height: calc(100vh - 60px); /* Adjust height to take up the space below the TopBar */
  box-sizing: border-box; /* Include padding in height calculation */
  font-family: "Open Sans"
}

.main-content h1 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #92BB45;
  font-family: "Open Sans"
}

/* Health Check Heading */
.health-check-heading {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff;
  font-family: "Open Sans"
}

/* Grid Layout for Status Overview Components */
.status-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns for the grid */
  gap: 20px;
  margin-top: 20px;
}

.status-overview-component {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.status-overview-component h2 {
  margin-bottom: 10px;
  font-size: medium;
  font-family: "Open Sans"
}

.status-overview-component ul {
  list-style: none;
  padding: 0;
}

.status-overview-component li {
  margin: 5px 0;
}

.status-overview-component li:before {
  content: "•";
  margin-right: 5px;
  color: #007bff;
  font-family: "Open Sans"
}

/* Color-coding based on the status */
.status-overview-component li.ok {
  color: green;
  font-family: "Open Sans"
}

.status-overview-component li.nok {
  color: red;
  font-family: "Open Sans"
}

/* Styles for the loading and empty data state */
.status-grid p {
  font-size: 18px;
  font-weight: bold;
  color: #555;
  font-family: "Open Sans"
}

.vin-dropdown {
  position: absolute;
  top: 20px;
  right: 20px;
}

.vin-dropdown-select {
  font-size: 16px;
  padding: 8px;
}

.hw-assist-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 15px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  z-index: 10; /* Ensure button stays above other elements */
  font-family: "Open Sans"
}

.hw-assist-button:hover {
  background-color: #0056b3;
}

.hw-assist-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 100;
  border-radius: 8px;
  width: 500px;
  color: #1A1B1C;
}

/* Background container styles */
.background {
  background-size: cover;
  background-position: center;
  display: flex;
  top: 0;
  left: 0;
  filter: contrast(110%);
  background-color: #1A1B1C;
  height: 100vh; /* Ensure it covers full height of the viewport */
}

/* Container to hold the Sidebar and main content */
.container {
  display: flex;
  height: 100%; /* Full height of the viewport */
}

/* Content Container to ensure proper width allocation */
.contentContainer {
  flex: 1; /* Make content take up remaining space */
  margin-left: 0vw;
  transition: margin-left 0.3s ease;
}

/* Styles for closing the popup */
.hw-assist-popup ul {
  list-style-type: none;
  padding-left: 0;
}

.close-popup-button {
  margin-top: 20px;
  padding: 8px 12px;
  background-color: #FF4C4C;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.close-popup-button:hover {
  background-color: #c13f3f;
}

/* Control Buttons Styles */
.control-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  width: 100%;
  max-width: 800px; /* Set a max-width to keep the div small */
  font-family: "Open Sans";
}

.control-buttons-container h2 {
  margin-bottom: 20px;
  color: #92BB45; /* Apply green color to the heading */
  font-family: "Open Sans";
  text-align: center; /* Center the heading */
  width: 100%; /* Ensure the heading takes full width */
  border-bottom: 2px solid #92BB45; /* Add a bottom border for separation */
  padding-bottom: 10px; /* Add padding below the heading */
}

.control-buttons {
  display: flex;
  flex-wrap: wrap; 
  justify-content: center; 
  gap: 10px; 
  width: 100%; 
  margin-top: 20px; 
}

.control-buttons button {
  flex: 0 1 200px; 
  padding: 10px 20px;
  margin: 5px; 
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  font-family: "Open Sans";
  text-align: center; 
}

.start-button {
  background-color: green;
  color: white;
}

.stop-button {
  background-color: red;
  color: white;
}

.start-button:hover {
  background-color: darkgreen;
}

.stop-button:hover {
  background-color: darkred;
}

.control-instances {
  color: #92BB45;
}

/* Styles for the new file upload container */
.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  width: 100%;
  max-width: 800px;
}

.file-upload-heading {
  font-size: 18px;
  margin-bottom: 20px;
  color: #92BB45; 
}

.file-upload-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.file-upload-container .text-box {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  resize: vertical; /* Allow vertical resizing */
  margin-bottom: 10px; 
}

.file-upload-container .file-input {
  display: none; 
}

.file-upload-container .file-input-label {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  color: #333333;
  text-align: center;
  margin-bottom: 10px;
}

.file-upload-container .file-message {
  margin-top: 10px;
  font-size: 14px;
  color: #007BFF;
}

.file-upload-container .submit-button,
.file-upload-container .upload-button,
.file-upload-container .reset-button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.file-upload-container .submit-button:hover,
.file-upload-container .upload-button:hover,
.file-upload-container .reset-button:hover {
  background-color: #0056b3;
}



.typeNames{
  color: #92BB45;
}

/*Bouncing Loader*/
.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 16px;
  height: 16px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
