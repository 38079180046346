.icon-slider {
    display: flex;
  }
  
  .icon-container {
    position: relative;
    margin-right: 20px;
  }
  
  .icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .icon-title {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
  }
  
  .icon-title::before {
    content: '';
    position: absolute;
    top: -5px;
    left: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
    transform: translateX(-50%);
  }
  